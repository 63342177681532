import { Routes } from '@angular/router';
import { SingleSignOnComponent } from '@building-x/common-ui-ng';

import { AuthGuard } from './guards/auth.guard';
import { LandingComponent } from './pages/landing/landing.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { AboutComponent } from './pages/main/about/about.component';
import { HomeComponent } from './pages/main/home/home.component';
import { MainComponent } from './pages/main/main.component';

export const routes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'about', component: AboutComponent },
      { path: '**', redirectTo: 'home' }
    ],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  { path: 'landing', component: LandingComponent },
  { path: 'sso', component: SingleSignOnComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'logout', component: LogoutComponent },
  { path: 'about', component: AboutComponent },
  { path: '**', redirectTo: 'main', pathMatch: 'full' }
];
