<div class="has-navbar-fixed-top">
  <si-navbar-primary appTitle="Building X">
    <si-navbar-item quickAction [item]="helpAction" />
  </si-navbar-primary>

  <div class="si-layout-main-padding container">
    <div class="row g-0 mb-9">
      <div class="col-md-4">
        <img
          alt="Building X logo"
          class="img-fluid rounded-start h-100"
          width="400"
          height="400"
          ngSrc="./assets/images/building-x.png"
          priority
        />
      </div>
      <div class="col-md-8 px-8">
        <h1 class="display-4">Building X</h1>
        <p>All building data at one place.</p>
        <button type="button" class="btn btn-secondary m-auto" (click)="signIn()">Login in</button>
      </div>
    </div>

    <div class="row mb-10">
      <div class="col">
        <div class="card">
          <img
            alt="..."
            class="card-img-top"
            width="320"
            height="180"
            ngSrc="./assets/images/siemens-power-utilities.webp"
            priority
          />
          <div class="card-body">
            <h2 class="card-title">Card title</h2>
            <p class="card-text"
              >This is a longer card with supporting text below as a natural lead-in to additional
              content. This content is a little bit longer.</p
            >
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <img
            alt="..."
            class="card-img-top"
            width="320"
            height="180"
            ngSrc="./assets/images/siemens-power-utilities.webp"
            priority
          />
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text"
              >This is a longer card with supporting text below as a natural lead-in to additional
              content. This content is a little bit longer.</p
            >
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <img
            alt="..."
            class="card-img-top"
            width="320"
            height="180"
            ngSrc="./assets/images/siemens-power-utilities.webp"
            priority
          />
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <p class="card-text"
              >This is a longer card with supporting text below as a natural lead-in to additional
              content. This content is a little bit longer.</p
            >
          </div>
        </div>
      </div>
    </div>

    <si-footer
      copyright="Siemens 2016-2024"
      [links]="[
        {
          title: 'About',
          link: '/main/components/si-about'
        },
        {
          title: 'Corporate Information',
          href: 'http://www.siemens.com/corporate-information'
        },
        {
          title: 'Privacy Notice',
          href: 'http://www.siemens.com/privacy-notice'
        },
        {
          title: 'Terms of Use',
          href: 'http://www.siemens.com/terms-of-use'
        }
      ]"
    />
  </div>
</div>
