import { Component, OnInit } from '@angular/core';
import { AVAILABLE_LANGUAGES, SiLoginAuthModule } from '@building-x/common-ui-ng';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { StatusType } from '@simpl/element-ng';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  standalone: true,
  imports: [SiLoginAuthModule, TranslateModule]
})
export class LoginComponent implements OnInit {
  warningText = `You are about to connect to productive data. Please note that changes will take effect immediately.`;
  backgroundImageUrl = 'assets/images/login-image/AccountManager.jpg';
  messageSeverity: StatusType = 'warning';
  inlineNotification = {
    message: 'Maintenance is planned at 10:00 - 12:00 GMT',
    severity: this.messageSeverity
  };
  languages = AVAILABLE_LANGUAGES.filter(
    language => language.value !== 'zh-Hans' && language.value !== 'zh-Hant'
  );
  inlineBannersList = [
    {
      severity: 'info' as StatusType,
      message: 'Message to be displayed' as string,
      id: 3
    },
    {
      heading: 'Heading',
      severity: 'success' as StatusType,
      message: 'Message to be displayed with Link' as string,
      action: {
        title: 'Read more',
        href: 'https://horizon.code.siemens.io/apps/horizon-ui/api/index.html',
        target: '_blank'
      },
      id: 4
    }
  ];
  // Dates are exclusive of the end date - banners will be visible until the day before the end date
  mfaStartDate = new Date('2024-05-21');
  mfaEndDate = new Date('2024-06-01');

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem('lang', event.lang);
    });
  }
}
