import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SiAboutComponent } from '@simpl/element-ng';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [SiAboutComponent, TranslateModule],
  templateUrl: './about.component.html',
  host: {
    class: 'si-layout-inner'
  }
})
export class AboutComponent {}
