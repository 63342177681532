import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@building-x/common-ui-ng';

@Component({
  selector: 'app-logout',
  template: '',
  standalone: true
})
export class LogoutComponent implements OnInit {
  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    this.authenticationService.signOut();
  }
}
