import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SiSharedModule } from '@building-x/common-ui-ng';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SiSharedModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  cookieUrl =
    '//assets.adobedtm.com/5dfc7d97c6fb/9f2b9af520c3/launch-9d9de04d5910-development.min.js';
}
