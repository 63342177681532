<si-login-page
  data-cy="login-page"
  [inlineBannersList]="inlineBannersList"
  [mfaStartDate]="mfaStartDate"
  [mfaEndDate]="mfaEndDate"
  [heading]="'APP.NAME' | translate"
  [warningText]="warningText"
  [subtitle]="'APP.CLAIM' | translate"
  [availableLanguages]="languages"
  [inlineNotification]="inlineNotification"
/>
