<si-navbar
  [appName]="'Building X - Home'"
  [showSecondaryNavbar]="false"
  [allowAccountsAppNavigationOnApply]="false"
  [currentAppPermission]="'app.assetMgr'"
  [defaultHelpPortalURL]="'https://siemens-uat.fluidtopics.net/home'"
  [additionalInfoLinks]="additionalInfoLinks"
  [navbarItems]="navbarItems"
  [doSelectAllPartitionsByDefault]="false"
  [persistPartitionsIds]="persistPartitionsIds"
  [infoLinks]="{ items: [{ title: 'About', link: '/main/about' }] }"
  [addNewCompany]="{
    items: [{ title: 'Add Company', link: '/main/unauthorized-ui' }]
  }"
  [showMyAccountLink]="true"
  [openCustomerSwitcher]="collapseCustomerSwitcher"
  [languages]="languages"
  [appSwitcherDataSource]="appSwitcherDataSource"
  [accountManagerUrl]="'https://account.apps.horizondev.cloud/#/sso/myprofile'"
  [enableOfflinePage]="true"
  [updateNavbarValues]="updateOrganization"
  [resetNavbar]="resetNavbar"
  [allowPartitionFilter]="true"
  [persistCustomerId]="persistCustomerId"
  (logout)="onLogout($event)"
  (customerSelection)="customerSelection($event)"
  (paramsData)="getParams($event)"
  (orgSelection)="onOrgChange($event)"
>
  <div class="si-layout-main-padding si-layout-fixed-height">
    <router-outlet />
  </div>
</si-navbar>
