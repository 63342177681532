import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthenticationService, SiProfileService } from '@building-x/common-ui-ng';

import { environment } from '../../environments/environment';

// key for identitySet
export const IDENTITY_STORAGE_KEY_NAME = 'identitySet';

// key for cognito
export const COGNITO_STORAGE_KEY_NAME = 'cognito';

// key to store customerIdpId for idp customers
export const CUSTOMER_IDP_ID_STORAGE_KEY_NAME = 'customerIdpId';

// key to store entryPointUrl
export const ENTRY_POINT_URL_STORAGE_KEY_NAME = 'entryPointUrl';

// key to store entryPointQueryParams
export const ENTRY_POINT_QUERY_PARAMS_STORAGE_KEY_NAME = 'entryPointQueryParams';

// key to store entryPointOutlets
export const ENTRY_POINT_OUTLETS_STORAGE_KEY_NAME = 'entryPointOutlets';

// provider to be set from url
export const PROVIDER_KEY_NAME = 'provider';

// key for customer Id
export const CUSTOMER_ID_STORAGE_KEY_NAME = 'customerId';

@Injectable()
export class AuthGuard {
  constructor(
    @Inject(AuthenticationService)
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(SiProfileService) private profileService: SiProfileService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const redirectUrl = document.head.getElementsByTagName('base')[0].href;
    return new Promise<boolean>((resolve, reject) => {
      this.authenticationService
        .init(
          environment.clientId,
          environment.issuer,
          '#/login',
          redirectUrl,
          environment.audience,
          environment.siemensIdBaseUrl
        )
        .then((fulfilled: any) => {
          if (route.url[0].path === 'login') {
            resolve(true);
            return;
          }

          if (this.authenticationService.isAuthenticated()) {
            this.assertValidIdentity(resolve);
          } else {
            if (route.url[0].path === 'sso') {
              this.authenticationService.oAuthService
                .loadDiscoveryDocumentAndTryLogin()
                .then((done: any) => resolve(done));
              return;
            }

            this.clearValidIdentity();

            const urlTree: any = this.router.parseUrl(state.url);
            const urlWithoutParams = urlTree.root.children.primary.segments
              .map((it: any) => it.path)
              .join('/');

            const outlets: any = {};
            const primaryChildren = urlTree.root.children.primary.children;
            Object.keys(primaryChildren).forEach((key: any) => {
              if (key !== 'numberOfChildren') {
                if (primaryChildren[key].segments.length) {
                  outlets[key] = primaryChildren[key].segments[0].path;
                }
              }
            });

            sessionStorage.setItem(ENTRY_POINT_URL_STORAGE_KEY_NAME, urlWithoutParams);
            sessionStorage.setItem(ENTRY_POINT_OUTLETS_STORAGE_KEY_NAME, JSON.stringify(outlets));
            sessionStorage.setItem(
              ENTRY_POINT_QUERY_PARAMS_STORAGE_KEY_NAME,
              JSON.stringify(state.root.queryParams)
            );

            this.router.navigate(['landing'], { relativeTo: this.route.root });
            resolve(false);
          }
        });
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }

  assertValidIdentity(resolve: any) {
    if (this.hasValidIdentity()) {
      resolve(true);
      return;
    }

    this.profileService.setIdentity().subscribe(
      () => {
        sessionStorage.setItem(IDENTITY_STORAGE_KEY_NAME, 'true');
        if (sessionStorage.getItem(ENTRY_POINT_URL_STORAGE_KEY_NAME)) {
          const redirectUrl = sessionStorage.getItem(ENTRY_POINT_URL_STORAGE_KEY_NAME);
          const redirectQueryParams = JSON.parse(
            sessionStorage.getItem(ENTRY_POINT_QUERY_PARAMS_STORAGE_KEY_NAME) as string
          );
          const outlets = JSON.parse(
            sessionStorage.getItem(ENTRY_POINT_OUTLETS_STORAGE_KEY_NAME) as string
          );

          sessionStorage.removeItem(ENTRY_POINT_URL_STORAGE_KEY_NAME);
          sessionStorage.removeItem(ENTRY_POINT_QUERY_PARAMS_STORAGE_KEY_NAME);
          sessionStorage.removeItem(ENTRY_POINT_OUTLETS_STORAGE_KEY_NAME);
          this.router.navigate([redirectUrl, redirectQueryParams, { outlets }]);
        }
        resolve(true);
      },
      () => {
        resolve(true);
      }
    );
  }

  hasValidIdentity() {
    return sessionStorage.getItem(IDENTITY_STORAGE_KEY_NAME) === 'true';
  }

  clearValidIdentity() {
    sessionStorage.removeItem(IDENTITY_STORAGE_KEY_NAME);
  }

  /**
  private objToQueryString(obj: Params): string {
    if (obj) {
      return Object.keys(obj)
        .map((i: any) => `${i}=${obj[i]}`)
        .join('&');
    } else {
      return '';
    }
  }
   */
}
