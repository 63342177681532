<!-- eslint-disable @angular-eslint/template/no-inline-styles -->
<div class="si-layout-header">
  <h1 class="si-layout-title si-layout-top-element">
    {{ 'HOME.HEADING' | translate }}
  </h1>
  <p class="si-layout-subtitle">
    {{ 'HOME.INTRO' | translate }}
  </p>
  <div class="si-layout-actions">
    <button type="button" class="btn btn-secondary">
      <i class="si-display-3 element-support"></i>
      {{ 'HOME.SUPPORT' | translate }}
    </button>
  </div>
</div>

<h2>Portfolio Heading Overview</h2>
<div class="row">
  <div class="col-12 col-sm-6 col-xl-4 col-xxl-3 mb-6">
    <si-card class="h-100" [heading]="'Total energy spending'">
      <div class="card-body" body>
        <div class="d-flex">
          <si-icon size="display-1" icon="element-power-pole" class="me-4" />
          <div>
            <span class="si-value-widget-value">500.93</span>
            <span class="si-value-widget-unit">kWh</span><br />
            <span class="si-value-widget-value">468.260,91</span>
            <span class="si-value-widget-unit">€</span>
            <div class="si-value-widget-description text-danger">
              <si-icon icon="element-up-1" />
              <span>79,21% to previous year</span>
            </div>
          </div>
        </div>
      </div>
    </si-card>
  </div>

  <div class="col-12 col-sm-6 col-xl-4 col-xxl-3 mb-6">
    <si-card class="h-100" [heading]="'Total cost'">
      <div class="card-body" body>
        <div class="d-flex">
          <si-icon size="display-1" icon="element-power-pole" class="me-4" />
          <div>
            <span class="si-value-widget-value">1.332,48</span>
            <span class="si-value-widget-unit">€</span><br />
            <span class="si-value-widget-value">468.260,91</span>
            <span class="si-value-widget-unit">€</span>
            <div class="si-value-widget-description text-success">
              <si-icon icon="element-down-1" />
              <span>21,09% to previous year</span>
            </div>
          </div>
        </div>
      </div>
    </si-card>
  </div>

  <div class="col-6 col-sm-6 col-md-4 col-xl-4 col-xxl-2 mb-6">
    <si-card class="h-100" [heading]="'Devices'">
      <div class="card-body" body>
        <div class="d-flex">
          <si-icon size="display-1" icon="element-controller" class="me-4" />
          <div>
            <span class="si-value-widget-value">80</span>
            <span class="si-value-widget-unit">Devices offline</span><br />
            <span class="si-value-widget-value">12</span>
            <span class="si-value-widget-unit">Pending updates</span>
          </div>
        </div>
      </div>
    </si-card>
  </div>

  <div class="col-6 col-sm-6 col-md-4 col-xl-4 col-xxl-2 mb-6">
    <si-card class="h-100" [heading]="'Onboarding sites'">
      <div class="card-body" body>
        <div class="d-flex">
          <si-icon size="display-1" icon="element-plant" class="me-4" />
          <div>
            <span class="si-value-widget-value">2</span>
            <span class="si-value-widget-unit">Missing</span><br />
            <span class="si-value-widget-value">1</span>
            <span class="si-value-widget-unit">In progress</span>
          </div>
        </div>
      </div>
    </si-card>
  </div>

  <div class="col-12 col-md-4 col-xl-4 col-xxl-2 mb-6">
    <si-card class="h-100" [heading]="'Top priorities'">
      <div class="card-body" body>
        <p class="text-danger">8 Maintenance required</p>
        <p class="text-warning">4 Inactive user accounts</p>
        <p class="text-warning">2 Unhealthy devices</p>
      </div>
      <div class="card-footer" footer>
        <a [siLink]="{ href: 'https://siemens.com' }">See more</a>
      </div>
    </si-card>
  </div>
</div>

<!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
<si-card
  style="height: 500px"
  class="w-100"
  [heading]="'Critical sites'"
  [secondaryActions]="chartActions"
>
  <div class="card-body" body>
    <div class="row">
      <div class="col-6">
        <!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
        <si-chart-circle
          #circleChart
          class="flex-grow-1 flex-shrink-1"
          style="height: 400px"
          [subTitle]="chartData.subTitle"
          [series]="chartData.series"
          [showLegend]="chartData.showLegend"
          (siResizeObserver)="circleChart.resize()"
        />
      </div>

      <div class="col-6">
        <!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
        <si-chart-cartesian
          #barChart
          class="flex-grow-1 flex-shrink-1"
          style="height: 400px"
          [xAxis]="{
            type: 'category',
            data: ['Site A', 'Site B', 'Site C', 'Site D', 'Site C']
          }"
          [yAxis]="{ type: 'value' }"
          [series]="[
            {
              type: 'bar',
              name: 'Visitor site',
              data: [18, 10, 20, 12, 18]
            },
            {
              type: 'bar',
              name: 'Door alarms',
              data: [6, 22, 25, 14, 26]
            },
            {
              type: 'bar',
              name: 'Fire safety alarms',
              data: [8, 3, 7, 12, 5]
            },
            {
              type: 'bar',
              name: 'Critical alarms',
              data: [24, 26, 19, 21, 24]
            }
          ]"
          (siResizeObserver)="barChart.resize()"
        />
      </div>
    </div>
  </div>
</si-card>

<h2 class="mt-8">Quick links</h2>
<div class="d-flex">
  <div class="card elevation-1 text-center p-6 me-6" style="width: 120px">
    <i class="si-display-3 element-trend"></i>
    <span>Trend analysis</span>
  </div>

  <div class="card elevation-1 text-center p-6 me-6" style="width: 120px">
    <i class="si-display-3 element-report-alt"></i>
    <span>Reports</span>
  </div>

  <div class="card elevation-1 text-center p-6 me-6" style="width: 120px">
    <i class="si-display-3 element-plant"></i>
    <span>Add site</span>
  </div>
  <div class="card elevation-1 text-center p-6 me-6" style="width: 120px">
    <i class="si-display-3 element-add-user"></i>
    <span>Invite user</span>
  </div>
</div>

<div class="d-flex mt-8 mb-8">
  <div class="me-6">
    <h2 class="">Learn more</h2>
    <div class="row h-100">
      <div class="col">
        <div class="card h-100">
          <img
            alt="..."
            class="card-img-top bg-base-0"
            width="180"
            height="180"
            ngSrc="./assets/images/building-x.png"
            priority
          />
          <div class="card-body si-link-widget">
            <h3 class="card-title">Access BX resources</h3>
            <a href="https://simpl.siemens.io">
              Video tutorials
              <i class="link-icon link-end element-right-2 flip-rtl"></i>
            </a>
            <a href="https://simpl.siemens.io">
              Online help
              <i class="link-icon link-end element-right-2 flip-rtl"></i>
            </a>
            <a href="https://developer.siemens.com/building-x-openness/overview.html">
              Develop with Building X Openness
              <i class="link-icon link-end element-right-2 flip-rtl"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card h-100">
          <img
            alt="..."
            class="card-img-top card-img-object-cover"
            width="320"
            height="180"
            ngSrc="./assets/images/building1.png"
            priority
          />
          <div class="card-body">
            <h3 class="card-title">Building X - Demo Request</h3>
            <p class="card-text"
              >A digital building platform that empowers building owners and operators to make
              data-driven decisions that lead to improved sustainability.</p
            >
            <button type="button" class="btn btn-secondary w-100"> Request a demo</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <h2 class="">News from BX</h2>
    <div class="row h-100">
      <div class="col">
        <div class="card h-100">
          <div class="card-body si-link-widget">
            <h3 class="card-title">Access BX resources</h3>
            <a href="https://simpl.siemens.io">
              Internal: IoT&#64;Siemens 2024 / Building X Keynote
              <i class="link-icon link-end element-right-2 flip-rtl"></i>
            </a>
            <a href="https://simpl.siemens.io">
              Insights: Crawl, Walk, Run: AI Powered Buildings
              <i class="link-icon link-end element-right-2 flip-rtl"></i>
            </a>
            <a href="https://developer.siemens.com/building-x-openness/overview.html">
              New reference: PRODEA Investments, Greece
              <i class="link-icon link-end element-right-2 flip-rtl"></i>
            </a>
            <a href="https://developer.siemens.com/building-x-openness/overview.html">
              Insights: Crawl, Walk, Run: AI Powered Buildings
              <i class="link-icon link-end element-right-2 flip-rtl"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card h-100">
          <img
            alt="..."
            class="card-img-top card-img-object-cover"
            width="320"
            height="180"
            ngSrc="./assets/images/rahul.png"
            priority
          />
          <div class="card-body">
            <h3 class="card-title">What’s Building X? What’s its impact for our customers?</h3>
            <p class="card-text"
              >Our typical Building X customers don’t manage just one but multiple buildings!<br /><br />Imagine
              effortlessly overseeing all these buildings from one spot – that’s the power of
              Building X. </p
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<span class="mt-6">&nbsp;</span>
