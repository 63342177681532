<si-about
  appName="Building X"
  icon="assets/images/simpl-logo.svg"
  [aboutTitle]="'NAV.ABOUT' | translate"
  [licenseInfo]="{ title: 'License', text: 'TODO: Insert license texts here' }"
  [subheading]="['Version 0.0.1']"
  [imprintLink]="{
    title: 'Corporate information',
    href: 'http://www.siemens.com/corporate-information'
  }"
  [privacyLink]="{ title: 'Privacy policy', href: 'http://www.siemens.com/privacy-notice' }"
  [termsLink]="{ title: 'Terms of use', href: 'http://www.siemens.com/terms-of-use' }"
/>
