import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, NgZone } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withHashLocation } from '@angular/router';
import {
  AppConfigService,
  AuthenticationService,
  OAuthService,
  SiAODSModule,
  SiAppDataService,
  SiAppListService,
  SiemensIdAuthenticationMockService,
  SiLoginAuthModule,
  SiLoginInterceptor,
  SiPermissionService,
  SiProfileService,
  SiSubscriptionsModule,
  SiUOModule
} from '@building-x/common-ui-ng';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SI_COPYRIGHT_DETAILS } from '@simpl/element-ng';
import { SiTranslateNgxTModule } from '@simpl/element-ng/ngx-translate';
import { UrlHelperService } from 'angular-oauth2-oidc';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from 'src/environments/environment';

import { routes } from './app.routes';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './pages/login/login.component';

export const createTranslateLoader = (http: HttpBackend): MultiTranslateHttpLoader =>
  new MultiTranslateHttpLoader(http, ['./assets/i18n/', './assets/i18n/common-ui-ng/']);

export const authenticationFactory = (
  oAuthService: OAuthService,
  ngZone: NgZone,
  http: HttpClient
): any => {
  if (environment.test) {
    return new SiemensIdAuthenticationMockService(ngZone);
  } else {
    return new AuthenticationService(
      oAuthService,
      environment.issuer,
      environment.msIssuer,
      environment.profileUrl,
      environment.userApiUrl
    );
  }
};

export const appConfig: ApplicationConfig = {
  providers: [
    LoginComponent,
    AppConfigService,
    OAuthService,
    UrlHelperService,
    {
      provide: AuthenticationService,
      useFactory: authenticationFactory,
      // eslint-disable-next-line @angular-eslint/sort-ngmodule-metadata-arrays
      deps: [OAuthService, NgZone, HttpClient]
    },
    AuthGuard,
    SiProfileService,
    SiAppListService,
    SiAppDataService,
    SiPermissionService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes, withHashLocation()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SiLoginInterceptor,
      multi: true
    },
    importProvidersFrom(
      SiLoginAuthModule,
      SiAODSModule.forRoot({
        apiUrl: `${environment.aodsUrl}`
      }),
      SiUOModule.forRoot({
        apiUrl: environment.userApiUrl,
        invitationCallbackUrl: environment.invitationCallbackUrl,
        product: environment.product,
        noGoogleApi: environment.test
      }),
      SiSubscriptionsModule.forRoot({
        apiUrl: environment.subscriptionApiUrl,
        invitationCallbackUrl: environment.invitationCallbackUrl,
        product: environment.product,
        noGoogleApi: environment.test
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpBackend]
        }
      }),
      SiTranslateNgxTModule
    ),
    {
      provide: SI_COPYRIGHT_DETAILS,
      useValue: {
        startYear: 2024
      }
    }
  ]
};
