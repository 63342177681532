import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  CircleChartSeries,
  SiChartCartesianComponent,
  SiChartCircleComponent
} from '@simpl/charts-ng';
import {
  MenuItem,
  SiIconComponent,
  SiLinkDirective,
  SiResizeObserverModule,
  SiSearchBarComponent
} from '@simpl/element-ng';
import { SiCardComponent } from '@simpl/element-ng/card';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    SiCardComponent,
    NgOptimizedImage,
    SiSearchBarComponent,
    SiIconComponent,
    SiChartCircleComponent,
    SiChartCartesianComponent,
    SiLinkDirective,
    TranslateModule,
    SiResizeObserverModule
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  host: {
    class: 'si-layout-inner'
  }
})
export class HomeComponent {
  chartData = {
    title: 'Are people safe',
    subTitle: undefined,
    series: [
      {
        radius: ['50%', '75%'],
        data: [
          { value: 15, name: 'Fire' },
          { value: 64, name: 'Security' },
          { value: 60, name: 'Operation' }
        ]
      }
    ] as CircleChartSeries[],
    showLegend: true,
    liveUpdate: undefined
  };

  chartActions: MenuItem[] = [
    { title: 'Export as PDF' },
    { title: 'Send via mail' },
    { title: 'Print' }
  ];
}
