import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import {
  AppSidePanelStateService,
  AuthenticationService,
  Language,
  NavbarItemTypes,
  PointOfContact,
  ProfileInfo,
  SiCookiesService,
  SiProfileService,
  SiSharedModule,
  UpdateNavbarType
} from '@building-x/common-ui-ng';
import { TranslateService } from '@ngx-translate/core';
import { NavbarItem, SiSidePanelService, SiToastNotificationService } from '@simpl/element-ng';
import { SiNavbarItemComponent, SiNavbarPrimaryComponent } from '@simpl/element-ng/navbar';
import { SiNavbarVerticalComponent } from '@simpl/element-ng/navbar-vertical';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [
    RouterOutlet,
    SiNavbarItemComponent,
    SiNavbarPrimaryComponent,
    SiNavbarVerticalComponent,
    SiSharedModule
  ],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
  host: {
    class: 'has-navbar-fixed-top d-block'
  }
})
export class MainComponent implements OnInit {
  languages: Language[] = [
    { value: 'en', name: 'English' },
    { value: 'de', name: 'German' }
  ];
  public appList!: NavbarItem[];
  navbarItems = [
    {
      title: 'Messages',
      icon: 'element-notification',
      badge: 5,
      badgeColor: 'danger',
      link: 'messages'
    },
    {
      title: 'Messages',
      icon: 'element-mail',
      badge: 2,
      badgeColor: 'danger',
      link: 'messages'
    }
  ];

  collapseCustomerSwitcher = false;
  public accountInfo: ProfileInfo = { title: '' };
  public accountItem = [];
  collapsed!: boolean;
  persistCustomerId: string | undefined;
  pointOfContactDetails: PointOfContact = {
    name: 'Pratik',
    email: 'pratik.gupta@siemens.com',
    phone: '+91-9970209322',
    hasError: false
  };
  persistPartitionsIds: string[] = [];
  additionalInfoLinks = [
    {
      title: 'Additional menu item',
      link: 'https://simpl.code.siemens.io/simpl-element/'
    }
  ];
  resetNavbar: Subject<boolean> = new Subject<boolean>();
  updateOrganization: Subject<UpdateNavbarType> = new Subject<UpdateNavbarType>();
  appSwitcherDataSource = environment.webappVerticalApiUrl;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly profileService: SiProfileService,
    private readonly translateService: TranslateService,
    private readonly sidePanelService: SiSidePanelService,
    private readonly router: Router,
    private readonly cookieService: SiCookiesService,
    private readonly toastNotificationService: SiToastNotificationService,
    private readonly appSidePanelStateService: AppSidePanelStateService
  ) {}

  ngOnInit(): void {
    this.profileService.getProfileData().subscribe(
      user => {
        this.accountInfo = {
          title: user.attributes.identity.fullName,
          image: user.attributes.profile.profileImage
        };
      },
      () => {
        this.toastNotificationService.queueToastNotification(
          'connection',
          this.translateService.instant('ERROR.CONNECTION_ERROR_TITLE'),
          this.translateService.instant('ERROR.CONNECTION_ERROR_TEXT')
        );
      }
    );
    const savedLanguage = localStorage.getItem('lang');
    if (savedLanguage) {
      this.translateService.use(savedLanguage);
    }

    setTimeout(() => {
      this.updateUrlParams();
    }, 10000);
    this.subscribeToCollapsePanel();
  }

  public logout(): void {
    this.authenticationService.signOut();
  }

  onOrgChange(ev: any) {}

  onLogout(ev: any) {
    this.router.navigate(['/logout']);
  }

  // to refresh navbar
  refreshNavbar() {
    this.persistCustomerId = this.cookieService.getCookie('customerId');
    this.resetNavbar.next(true);
  }

  //Commented this function as we found that is not in use and giving error due to upgrade.
  // updateOrgName() {
  //   this.updateOrganization.next();
  // }

  updateUrlParams() {
    const paramObj: UpdateNavbarType = {
      navbarItemType: NavbarItemTypes.UpdateParams,
      objectValue: [
        {
          keyId: 'site',
          value: '123'
        }
      ]
    };
    this.updateOrganization.next(paramObj);
  }

  getParams(ev: any) {
    console.log(ev);
  }

  customerSelection(ev: any) {
    console.log(ev);
  }

  subscribeToCollapsePanel() {
    this.appSidePanelStateService.isSidePaneCollapsed.subscribe(result => {
      this.collapsed = result;
    });
    return this.collapsed;
  }

  closeCustomerSwitcher() {
    this.sidePanelService.setSidePanelContent(undefined);
    this.collapseCustomerSwitcher = false;
    this.appSidePanelStateService.isSidePaneCollapsed.next(true);
  }
}
