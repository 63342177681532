import { NgOptimizedImage } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthenticationService } from '@building-x/common-ui-ng';
import { TranslateModule } from '@ngx-translate/core';
import {
  MenuItem,
  SiEmptyStateComponent,
  SiFooterComponent,
  SiLandingPageComponent,
  SiNavbarItemComponent,
  SiNavbarPrimaryComponent
} from '@simpl/element-ng';
import { environment } from 'src/environments/environment';

import { SettingsService } from '../../services/settings.service';

const redirectUrl = document.head.getElementsByTagName('base')[0].href;

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [
    RouterLink,
    SiLandingPageComponent,
    TranslateModule,
    SiNavbarPrimaryComponent,
    SiNavbarItemComponent,
    NgOptimizedImage,
    SiEmptyStateComponent,
    SiFooterComponent
  ],
  templateUrl: './landing.component.html'
})
export class LandingComponent {
  availableLanguages = this.settingsService.availableLanguages;
  helpAction: MenuItem = {
    title: 'Sign in',
    icon: 'element-user',
    link: '../login',
    action: () => this.signIn()
  };

  private readonly authenticationService = inject(AuthenticationService);
  constructor(private settingsService: SettingsService) {}

  async signIn() {
    await this.authenticationService.init(
      environment.clientId,
      environment.issuer,
      '#/login',
      redirectUrl,
      environment.audience,
      environment.siemensIdBaseUrl
    );
    this.authenticationService.loginButtonClicked.next(true);
    this.authenticationService.signIn();
  }
}
