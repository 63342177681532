export const environment = {
  production: true,
  test: false,
  webappVerticalApiUrl: 'https://int.webapp.horizonint.cloud/api/v1/me/apps',
  subscriptionApiUrl: 'https://product-int.subscription.horizonint.cloud/api/v2',
  userApiUrl: 'https://prodint.um.user.horizonint.cloud/api/v2',
  aodsUrl: 'https://product-int.aods.horizonint.cloud/api/v2',
  invitationCallbackUrl: 'http://localhost:4200/main/invitation',

  clientId: 'WygjqePXRVOKqCe6ArKWm9tbYfhrrDeS',
  audience: 'https://horizonint.cloud',

  issuer: 'https://siemens-qa-bt-015.eu.auth0.com/',
  msIssuer: 'https://btprodeu.piam.eu1.mindsphere.io/oauth/token',

  profileUrl: 'https://uss.login-dev.siemens.com/userprofile',
  product: 'Building X Landing',
  siemensIdBaseUrl: 'https://login-qa.siemens.com',
  cookieUrl: '//assets.adobedtm.com/5dfc7d97c6fb/9f2b9af520c3/launch-b25a29efa7a1-staging.min.js'
};
